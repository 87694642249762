:root {
  --primary-color: #673de6;
  --secondary-color: #491cd5;
  --bg-ea-base: #d9cef9 !important;
  --bg-ea-base-2: #e5dff7 !important;
  --bg-ea-success: #44d576 !important;
  --bg-ea-danger: #ee6565 !important;
  --bg-ea-gray: #cacaca !important;
  --bg-eee: #eee !important;
  --bg-ddd: #ddd !important;
  --bg-half-gray: #c5c1c1 !important;
  --bg-aaa: #aaa !important;
}

.pwa-popup p {
  padding: 0.5rem !important;
  text-align: right !important;
}

.pwa-popup .iOSPWA-description {
  padding: 0.5rem 2rem !important;
  text-align: right !important;
}

.pwa-popup img {
  padding: 0.5rem !important;
  background-color: white !important;
  border-radius: 10px !important;
}

.w-48 {
  width: 48%;
}

.multiple-devices-notification {
  width: 35%;
}

.qrcode {
  width: 40%;
  height: auto;
}

.loading {
  animation: loading 2s linear infinite;
  pointer-events: none !important;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.loading-grow {
  animation: spin 2s linear infinite;
  pointer-events: none !important;
}

@keyframes loading-grow {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.social-login-container {
  display: flex;
  justify-content: center;
  margin: 2%;
}

.social-login-btns {
  min-width: 48%;
  margin: 0 2%;
}

.facebook-login {
  background-color: rgba(24, 119, 242, 1) !important;
  color: white !important;
  border-radius: 50px !important;
}

.facebook-login:hover {
  background-color: rgb(21, 106, 218) !important;
}

.google-login {
  background-color: #ef523e !important;
  color: white !important;
  border-radius: 50px !important;
}

.google-login:hover {
  background-color: #dd4b39 !important;
}

.allow-google iframe {
  pointer-events: all;
  margin: auto;
}

.closed-for-updates img {
  max-width: 500px;
  max-height: 500px;
}

@media screen and (max-width: 600px) {
  .closed-for-updates img {
    max-width: 200px;
    max-height: 200px;
  }

  .qrcode {
    width: 80%;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.wa-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.wa-float:hover {
  color: var(--primary-color);
}

.disappearing-div {
  animation: disappear 0.5s ease 5s forwards;
  transition: opacity 0.5s ease; /* Added smooth transition */
}

.clickable-question-card {
  cursor: pointer;
}

.clickable-question-card:hover {
  border: 1px solid var(--primary-color);
  border-radius: 15px;
  height: fit-content;
}

.--plyr-color-main {
  color: var(--primary-color) !important;
}

.plyr {
  width: 100% !important;
}

.plyr__video-embed iframe {
  pointer-events: none !important;
}

.fr-home-blocks {
  width: 100%;
  border-radius: 100px;
  box-shadow: 0px 0px 20px 2px #00000035;
}

/* .fr-home-blocks-container {
  transform: translateY(-120px);
  height: 0;
} */

.responsive-bg {
  height: 100vh;
}

.res-timers {
  max-width: 40% !important;
  text-align: center !important;
}

.responsive-dashboard {
  width: 75% !important;
}

.responsive-padding-card {
  padding: 3rem 3rem !important;
}

.responsive-lesson {
  padding: 3rem !important;
}

.responsive-purchase-code {
  flex-direction: row !important;
}

.responsive-purchase-fawry {
  width: 50% !important;
}

.responsive-quiz-grade {
  width: 50% !important;
}

.responsive-pages {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.responsive-padding-pages-containers {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.responsive-grade {
  width: 50% !important;
}

.responsive-vid-preview {
  width: 50% !important;
}

.datetimeinput {
  width: 40% !important;
}

.lesson-file-btn {
  max-width: 200px;
}

@media screen and (max-width: 600px) {
  .multiple-devices-notification {
    width: 90%;
  }

  .social-login-btns {
    min-width: 100%;
    margin: 0 auto 2% auto;
  }

  .social-login-container {
    display: block;
    justify-content: center;
    margin: 0;
    text-align: center;
  }

  .lesson-file-btn {
    max-width: 2000px;
  }

  .datetimeinput {
    width: 100% !important;
  }

  .responsive-vid-preview {
    width: 100% !important;
  }

  .responsive-grade {
    width: 100% !important;
  }

  .responsive-padding-pages-containers {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .responsive-pages {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .responsive-quiz-grade {
    width: 90% !important;
  }

  .responsive-purchase-fawry {
    width: 90% !important;
  }

  .responsive-purchase-code {
    flex-direction: column !important;
  }

  .responsive-lesson {
    padding: 0.5rem !important;
  }

  .responsive-padding-card {
    padding: 1.5rem 0.25rem !important;
  }

  .responsive-dashboard {
    width: 100% !important;
  }

  /* .fr-home-blocks-container {
    transform: translateY(0px);
    height: 100%;
    margin-bottom: 1rem;
  } */

  .res-timers {
    max-width: 90% !important;
    text-align: center !important;
  }

  .responsive-counters {
    min-width: 100%;
  }

  .responsive-bg {
    height: 50vh;
  }

  .responsive-typewriter {
    font-size: 1.5rem !important;
  }

  .responsive-typewriter-img {
    display: none !important;
  }
}

.quiz-ended {
  pointer-events: none;
}

.progress-bar {
  background-color: var(--primary-color) !important;
}

.progress {
  background-color: var(--bg-ea-base);
}

.form-check-input:checked {
  background-color: var(--primary-color) !important;
}

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  text-align: right;
}

.content {
  flex-grow: 1;
  min-height: 84vh;
  background: #eee;
}

html,
body {
  height: 100%;
  margin: 0;
}

.footer {
  max-height: 10vh;
  height: 50px;
  background-color: #f8f9fa;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-nav {
  display: flex;
  flex-direction: row;
}

/* .wallet {
  border: 2px solid var(--primary-color);
  border-radius: 10px;
} */

.purchase-options {
  border: 3px solid var(--primary-color);
  background: #fff;
  padding: 3px 10px;
}

.purchase-options:focus {
  border: 2px solid var(--primary-color);
}

.sticky-footer {
  position: absolute;
  background-color: #fff;
  margin-top: 10px;
  bottom: 0;
  width: 100%;
  height: 50px;
}

.elite-video-player-container {
  position: relative;
}

.elite-watermark {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 1;
  font-size: 16px;
  color: red;
  /* Additional styles for the watermark */
}

.purchase-block {
  display: flex;
  flex-direction: column;
  text-align: center;
}

/* .vjs-icon-play {
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/videojs/7.8.4/video-icons.svg#play) !important;
}
.vjs-icon-play,
.vjs-icon-pause {
  color: red;
} */

iframe.vjs-tech {
  /* pointer-events: none; */
  border-radius: 15px;
}

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700&display=swap");
/* @import '~@vimeo/player/dist/player.css'; */

/* Your other CSS styles */

/* base styles */

.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid rgb(255, 255, 255); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  /* letter-spacing: .15em; */ /* Adjust as needed */
  animation: typing 2s steps(40, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(255, 255, 255);
  }
}

.chat-body {
  flex-grow: 1;
}

.chat-container {
  display: flex;
  flex-direction: column;
}

.agora_video_player {
  border-radius: 15px !important;
  position: relative !important;
  max-height: 500px;
}

#student-video-container div {
  background-color: transparent !important;
}

.bg-ea-primary {
  background-color: var(--primary-color) !important;
}

.bg-ea-secondary {
  background-color: var(--secondary-color) !important;
}

.bg-ea-base {
  background-color: var(--bg-ea-base);
}

.bg-ea-base-2 {
  background-color: var(--bg-ea-base-2);
}

.bg-ea-success {
  background-color: var(--bg-ea-success);
}

.bg-ea-danger {
  background-color: var(--bg-ea-danger);
}

.bg-ea-gray {
  background-color: var(--bg-ea-gray);
}

.bg-eee {
  background-color: var(--bg-eee);
}

.bg-ddd {
  background-color: var(--bg-ddd);
}

.bg-half-gray {
  background-color: var(--bg-half-gray);
}

.bg-aaa {
  background-color: var(--bg-aaa);
}

.text-ea-primary {
  color: var(--primary-color) !important;
}

.text-ea-secondary {
  color: var(--secondary-color) !important;
}

.rounded-ea {
  border-radius: 15px !important;
}

.border-ea {
  border: 1px solid var(--primary-color);
}

.border-ea-img {
  border: 2px solid var(--primary-color);
}

.border-ea-2 {
  border: 1px solid var(--primary-color);
}

.btn-outline-primary {
  border: 1px solid var(--primary-color) !important;
  background-color: #fff;
  color: var(--primary-color);
}

.btn-outline-primary:hover {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: white !important;
}

.btn-outline-primary:active {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
  color: white !important;
}

.quiz-radio:checked {
  background-color: var(--primary-color) !important;
}

.btn-check:checked + label {
  background-color: var(--primary-color) !important;
  color: white;
}

.grow:hover {
  color: var(--primary-color) !important;
}

.text-333 {
  color: #333 !important;
}

.link-title {
  color: #333 !important;
}

.link-title:hover {
  color: var(--primary-color) !important;
}

.bg-purple-svg {
  background-image: url("https://eliteacademyeg.com/wp-content/uploads/2023/04/purple-background-01-1.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: none !important;
}

.ea-btn {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.ea-btn:hover {
  background-color: var(--secondary-color) !important;
  color: white !important;
}

.nav-item > .ea-toggle.active {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.ea-toggle-2.active {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.ea-toggle-2 {
  text-align: right;
}

.dir-rtl {
  direction: rtl !important;
}

* {
  font-family: "Cairo" !important;
}

.navbar {
  padding: 5px 10%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin: 0 auto;
}

.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}

.nav-components {
  margin: 0 auto;
}

.navbar .links {
  margin-left: auto;
}
.navbar .links:hover {
  color: var(--primary-color);
}

.navbar a:hover {
  color: var(--primary-color);
}

.ea-bg-image {
  position: relative; /* Set position to relative for the pseudo-element to be positioned relative to this div */
  background-image: url("https://eliteacademyeg.com/wp-content/uploads/2023/04/12079894_4905784-scaled.jpg");
  background-color: white;
  background-size: 30%;
  background-repeat: no-repeat; /* Set background repeat to no-repeat */
  background-position: center; /* Center the background image */
}

.ea-bg-image::before {
  content: ""; /* Add content to the pseudo-element */
  position: absolute; /* Set position to absolute for the pseudo-element to cover the entire div */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(
    233,
    78,
    82,
    0.7
  ); /* Set the background color and opacity of the pseudo-element */
}

.ea-card-icon {
  font-weight: bold !important;
  font-size: 2.5rem;
}

@media (max-width: 450px) {
  .res-tabs {
    display: flex !important;
  }

  .res-tabs li {
    width: 100% !important;
    padding: 2rem auto !important;
  }

  .res-btn-div {
    display: block !important;
    height: auto !important;
  }

  .res-btn {
    width: 100% !important;
    padding: 7px !important;
    margin: 3px auto;
  }

  .res-row {
    flex-direction: column !important;
    text-align: center !important;
  }

  .res-text-sm {
    font-size: small !important;
  }
  .res-text-md {
    font-size: medium !important;
  }

  .ea-card-icon {
    font-size: 2rem !important;
  }

  .content {
    overflow-x: hidden !important;
  }

  .login-register-forms {
    width: 100% !important;
    margin-left: unset !important;
    margin: 1rem !important;
  }

  .login-register-wrapper {
    margin-left: unset !important;
  }

  .login-register-wrapper-grid {
    display: block !important;
  }
}

.login-register-wrapper-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto;
  gap: 1rem;
  padding: 1rem;
}

.login-register-wrapper {
  display: flex;
  justify-content: left;
  margin-left: 5%;
}

body {
  margin: 0;
  padding: 0;
}

.homepage-img {
  max-width: 90%;
  max-height: 100%;
}

.password-reset {
  width: 50%;
}

.notification-transition {
  transition: all 2s ease-in-out !important;
}

.elite-notification {
  position: fixed;
  height: fit-content;
  width: 100%;
  top: 1rem;
  right: 1rem;
  z-index: 9999;
}

.notification-begin {
  position: fixed;
  top: -6rem;
  right: 1rem;
  z-index: 9999;
  padding: 0.5rem 1rem;
  margin: 10px 0;
  width: 80%;
  height: fit-content;
  background-color: var(--primary-color);
  color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.notification-wrapper {
  position: relative;
  padding: 0.5rem 1rem;
  margin: 10px 0;
  width: 80%;
  height: fit-content;
  background-color: var(--primary-color);
  color: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.notification-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.notification-body {
  margin: 0;
  padding: 0;
}

.notification-close {
  position: absolute;
  top: 2px;
  left: 10px;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
  font-weight: lighter;
}

/* .notification-wrapper.show {
  display: block;
} */

@media (max-width: 600px) {
  .password-reset {
    width: 90% !important;
  }
}

@media screen and (max-width: 800px) {
  .content {
    min-height: calc(81vh + 3px);
  }
}
